@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-secondary bg-body text-white relative leading-relaxed font-extralight;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-primary;
  }
  a {
    @apply text-[15px] font-medium;
  }
  .nav-link {
    @apply after:w-6 after:h-1 after:absolute after:left-0 after:-bottom-2 after:rounded-full after:bg-transparent after:hover:bg-[#b936f5] after:transition-all after:duration-300 relative;
  }
  .btn {
    @apply font-medium h-12 px-8 bg-gradient-to-r from-[#b936f5] to-[#f1006c] rounded text-[15px] hover:bg-gradient-to-l hover:from-[#b936f5] hover:to-[#f1006c] outline-none flex items-center justify-center cursor-pointer;
  }
}

.h-\[30px\] {
  height: auto;
  width: 150px;
}

.modal {
  width: 100vw;
  height: 100vh;
  margin: 0px auto;
  position: fixed;
  top: 0px;
  left: 0px;
  justify-content: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  z-index: 999999999999;
  background-color: rgba(0,0,0,0.8);

}

.modal-content {
  width: 90%;  
  margin: 0px auto;
  max-width: 800px;
  position: relative;
  vertical-align: middle;
}
  
.close {
  text-align: right;
}
.w-full {
  width: 100%;
  border-radius: 4px;
}


  


